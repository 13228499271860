import { createWebHistory, createRouter } from "vue-router";
import Index from "@/components/Index.vue";
import FabricacaoDetails from "@/components/Details/Frabricacao.vue";
import ManutencaoDetails from "@/components/Details/Manutencao.vue";
import UsinagemDetails from "@/components/Details/Usinagem.vue";
import MandrilhamentoDetails from "@/components/Details/Mandrilhamento.vue";
import CromoDetails from "@/components/Details/Cromo.vue";
import Engenharia from "@/components/Details/Engenharia.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Index,
  },
  {
    path: "/fabricacao",
    name: "Fabricacao",
    component: FabricacaoDetails,
  },
  {
    path: "/manutencao",
    name: "Manutencao",
    component: ManutencaoDetails,
  },
  {
    path: "/usinagem",
    name: "Usinagem",
    component: UsinagemDetails,
  },
  {
    path: "/mandrilhamento-brunimento-trepanacao",
    name: "Mandrilhamento",
    component: MandrilhamentoDetails,
  },
  {
    path: "/cromo-duro",
    name: "CromoDuro",
    component: CromoDetails,
  },
  {
    path: "/engenharia-projetos",
    name: "Engenharia",
    component: Engenharia,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;