// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics,setAnalyticsCollectionEnabled, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDJqFx-LO1MzMbNmmIBqoiGDcOauibLZkQ",
  authDomain: "grupo-macih.firebaseapp.com",
  databaseURL: "https://grupo-macih-default-rtdb.firebaseio.com",
  projectId: "grupo-macih",
  storageBucket: "grupo-macih.appspot.com",
  messagingSenderId: "351490256338",
  appId: "1:351490256338:web:1b1d30f5ece4220eb2ac0b",
  measurementId: "G-7DSEWZXSDK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
setAnalyticsCollectionEnabled(analytics, true);

export const logAnalyticsEvent = function (eventName, eventParams) {
  return logEvent(analytics, eventName, {
    ...eventParams,
  });
};
logEvent(analytics, 'notification_received');

export default {app, analytics};