<template>
  <section id="about" class="container about">
    <div class="row mt-5 mb-5 align-items-center justify-content-center">
      <div class="col-12 col-md-6">
        <h2 class="text-center text-md-start">Quem somos?</h2>
        <div class="mt-2 text-center text-md-start">
          <p>
            Localizado em Piracicaba/SP, o GRUPO MACIH é uma instituição que atua com excelência no setor metalúrgico,
            proporcionando soluções eficientes para recuperação e fabricação de cilindros hidráulicos, além dos demais
            produtos e serviços prestados a nossos clientes e parceiros.
            Para executar todos os processos dentro da empresa, a MACIH se mantem equipada de máquinas e instrumentos de
            alta categoria e precisão, profissionais com grande conhecimento e experiência no ramo, e claro, matérias
            primas, reparos/vedações e produtos de procedência e qualidade classe A, afim de garantir e oferecer com
            eminência, sucesso absoluto no produto/serviço prestado e satisfação absoluta em nosso cliente final!!!
          </p>
        </div>
        <div class="row mb-4">
          <!-- <div
            class="col-12 mt-4 col-md-6 d-flex justify-content-center justify-content-md-start"
          >
            <button class="btn btn-warning">Whatsapp</button>
          </div>
          <div
            class="col-12 mt-4 col-md-6 d-flex justify-content-center justify-content-md-start"
          >
            <button class="btn btn-warning">Solicitar orçamento</button>
          </div> -->
        </div>
      </div>
      <div class="col-12 col-md-6 p-5 p-md-0 about-gallery">
        <img src="@/assets/img/home-4.webp" class="img-1" alt="..." />
        <img src="@/assets/img/home-4.webp" class="img-2 mt-4" alt="..." />

      </div>
    </div>
  </section>
</template>

<script>
import img from "@/assets/img/about.jpg";

export default {
  name: "ServicesComponent",
  data() {
    return {
      imageSrc: img, // URL da imagem que será exibida
    };
  },
};
</script>

<style lang='scss'>
.about {
  min-height: 90vh;
  box-sizing: border-box !important;
  display: flex;

  button {
    width: 80%;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: rgb(255 144 3);
    color: #fff;
    transition: background-color 0.5s ease;
  }


  img {
    height: auto;
    object-fit: contain;
    width: 100%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
  }


  @media(min-width: 1000px) {
    .about-gallery {
      position: relative;
      height: 500px;

      .img-1 {
        position: absolute;
        top: 0px;
        left: 0px;
        height: auto;
        object-fit: contain;
        width: 350px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
      }

      .img-2 {
        position: absolute;
        bottom: 0px;
        right: 0px;
        height: auto;
        object-fit: contain;
        width: 350px;
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
      }
    }
  }
}
</style>