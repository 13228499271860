<template lang="">
  <Home />
  <div class="content">
    <About />
  </div>
  <div class="content black">
    <Services />
  </div>
  <div class="content">
    <Portifolio />
  </div>
  <div class="content black">
    <Form />
  </div>
  <div class="content">
    <Blog />
  </div>
  
</template>
<script>
  import Home from "@/components/Home.vue";
  import Services from "@/components/Services.vue";
  import About from "@/components/About.vue";
  import Portifolio from "@/components/Portifolio.vue";
  import Form from "@/components/Form.vue"
  import Blog from "@/components/Blog.vue";

  export default {
    name: "IndexPage",
    components: {
      Home,
      Services,
      About,
      Portifolio,
      Form,
      Blog,
    },
  };
</script>
<style lang=""></style>
