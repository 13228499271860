<template>
  <main id="services" class="container">
    <div class="row services mt-5 mb-5">
      <div class="title-service mb-2">
        <h2>Nossos serviços</h2>
        <p>Conheça nossos serviços e faça um orçamento grátis agora!</p>
      </div>

      <div class="card mb-3 m-md-2">
        <div class="row g-0">
          <div class="col-md-12">
            <div class="card-body" @click="$router.push('/fabricacao')">
              <h3 class="card-title">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                Fabricação
              </h3>
              <p class="card-text">
                No quesito fabricação, o GRUPO MACIH desenvolve soluções para as mais diversas áreas e aplicações,
                atendendo 100% das necessidades de nossos clientes. Portados de uma fortíssima estrutura, hoje entregamos
                em torno de 3800 cilindros/mês, além de outras demandas extra possam surgir.
              </p>
              <small class="row d-flex justify-content-center align-items-center">Clique para saber mais</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 m-md-2" @click="$router.push('/manutencao')">
        <div class="row g-0">
          <div class="col-md-12">
            <div class="card-body">
              <h3 class="card-title">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                Recuperação
              </h3>
              <p class="card-text">
                Se tratando de recuperação de cilindros, nossa equipe de especialistas trata toda todas as situações e
                processo com extrema rigidez, desde a desmontagem/peritagem do equipamento, até os processo finais de
                testes em bancada hidráulica, garantindo assim a eficácia do serviço executado, lucratividade para seu
                equipamento e satisfação total de nossos clientes!
              </p>
              <small class="row d-flex justify-content-center align-items-center">Clique para saber mais</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 m-md-2" @click="$router.push('/usinagem')">
        <div class="row g-0">
          <div class="col-md-12">
            <div class="card-body">
              <h3 class="card-title">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                Usinagem
              </h3>
              <p class="card-text">
                Com diversidade de serviços executados no ramo de usinagem, nosso grupo dispõe de equipamentos de pequeno,
                médio e grande porte, de variadas aplicações, marcas e modelos.
              </p>
              <small class="row d-flex justify-content-center align-items-center">Clique para saber mais</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 m-md-2" @click="$router.push('/mandrilhamento-brunimento-trepanacao')">
        <div class="row g-0">
          <div class="col-md-12">
            <div class="card-body">
              <h3 class="card-title">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                Mandrilhamento, Brunimento e trepanação
              </h3>
              <p class="card-text">
                Mandrilhamento é processo utilizado para aumentar furos ou
                melhorar a qualidade de um existente, brunimento tem o objetivo
                de eliminar riscos, poros ou imperfeições de diâmetro e
                geometrias internas, enquanto a trepanação é usada para fazer
                furos grandes e retirar material em forma de miolo.
              </p>
              <small class="row d-flex justify-content-center align-items-center">Clique para saber mais</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 m-md-2" @click="$router.push('/cromo-duro')">
        <div class="row g-0">
          <div class="col-md-12">
            <div class="card-body">
              <h3 class="card-title">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                Cromo duro
              </h3>
              <p class="card-text">
                O maior benefício do banho de cromo duro em superfície novas e usadas, além de acabamento é a proteção!
                Em peças novas o cromo aumenta o tempo de vida útil do material ou equipamento, proporcionando melhor
                desempenho do mesmo.
              </p>
              <small class="row d-flex justify-content-center align-items-center">Clique para saber mais</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card mb-3 m-md-2" @click="$router.push('/engenharia-projetos')">
        <div class="row g-0">
          <div class="col-md-12">
            <div class="card-body">
              <h3 class="card-title">
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                Engenharia e projetos especiais
              </h3>
              <p class="card-text">
                O Grupo Macih tem uma equipe de engenheiros especializados em
                oferecer soluções e melhorias para empresas e equipamentos,
                visando aumentar lucros e reduzir perdas e gastos. Eles utilizam
                tecnologias inovadoras para executar seus serviços com precisão
                e qualidade.
              </p>
              <small class="row d-flex justify-content-center align-items-center">Clique para saber mais</small>

            </div>
          </div>
        </div>
      </div>

      <div class="row button-area mt-5">
        <div class="col-12 col-md-6 d-flex align-item-center justify-content-center justify-content-md-end">
          <a class="btn btn-warning" href="https://api.whatsapp.com/send?phone=551934288498" target="_blank">Whatsapp</a>
        </div>
        <div
          class="col-12 col-md-6 d-flex align-item-center justify-content-center justify-content-md-start mt-3 mt-md-0">
          <a class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#exampleModal">Solicitar orçamento</a>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import img from "@/assets/img/about.jpg";

export default {
  name: "ServicesComponent",
  data() {
    return {
      imageSrc: img, // URL da imagem que será exibida
    };
  },
};
</script>

<style lang='scss' scoped>
.title-service {
  width: 100%;
  height: auto;
  text-align: center;
  color: rgb(255 144 3);
}

.services {
  min-height: 90vh;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0px;

  .card {
    max-width: 30%;
    min-width: 350px;
    min-height: 350px;
    max-height: 350px;
    background-color: #ffffffe4;

    p {
      font-size: 14px !important;
    }

    .card-body {
      min-height: auto;
      height: 100%;
    }

    .card-title {
      display: flex;
      align-items: center;

      i {
        color: #fff;
        font-size: 1.2rem;
        margin-right: 10px;
        border-radius: 50%;
        background-color: rgb(255 144 3);
        padding: 5px;
      }

      font-size: 16px;
    }

    .card-text {
      font-size: 14px;
      position: relative;
      min-height: 230px;


    }

    small {
      color: #000000b9;
      font-size: 14px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      cursor: pointer;
      border: 2px solid rgb(255 144 3);
      transform: scale(1.05);

      .card-title {
        i {
          color: black;
        }
      }
    }
  }

  .button-area {
    a {
      border-radius: 50px;
      min-width: 300px;
    }
  }
}
</style>