<template>
  <section id="service-page" class="container service-page">


    <div class="row mt-5 mb-2 align-items-start justify-content-center">
      <h2 class="text-center text-md-center mt-5">Usinagem</h2>
      <div class="mt-2 text-center text-md-center">
        <p>
          Com diversidade de serviços executados no ramo de usinagem, nosso grupo dispõe de equipamentos de pequeno,
          médio e grande porte, de variadas aplicações, marcas e modelos.
          <br>
          <br>
          - TORNOS CONVENCIONAIS <br>
          - TORNOS CNC <br>
          - FRESAS <br>
          - CENTRO DE USINAGEM <br>
          - MANDRILHADORAS <br>
          - FURADEIRAS <br>
        </p>

        <p>
          Conseguimos executar em qualquer quantidade todos os tipos de serviços de usinagem que possam ser executados
          por nossas máquinas, seguindo rigorosamente o projeto, desenho ou modelo da peça.
          Faça um orçamento agora!
        </p>
      </div>
    </div>

    <div class="row mt-2 mb-5 align-items-start justify-content-center gallery">
      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

    </div>



    <!-- <div class="row text-center">
      <h2 class="text-center">Dados técnicos</h2>
      <table class="table mt-2">
        <thead>
          <tr>
            <th scope="col">Produto</th>
            <th scope="col">Largura</th>
            <th scope="col">Altura</th>
            <th scope="col">Comprimento</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
        </tbody>
      </table>
    </div> -->
  </section>
</template>

<script>
export default {
  name: "FrabricacaoDetail",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
    let navBar = document.getElementById("navbar");
    navBar.style.backgroundColor = "black";
  },
};
</script>

<style lang="scss" scoped>
.service-page {
  margin-top: 10vh;
  min-height: 90vh;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;

  button {
    width: 80%;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: rgb(255 144 3);
    color: #fff;
    transition: background-color 0.5s ease;
  }

  .gallery {
    img {
      max-width: 300px;
      margin: 24px;

      &:hover {
        transform: scale(1.3);
        transition: 0.5s cubic-bezier(0, 0, .3, 1);
        cursor: pointer;
      }
    }
  }
}
</style>
