<template>
  <section class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="title-service mt-5 mb-5 text-center">
          <h2>Fique por dentro das novidades do Grupo Macih</h2>
          <p>
            <small>Cadastre seu email em nossa lista e fique sempre atualizado</small>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 mt-md-5 mb-5 ">

        <div class="row align-items-center justify-content-center">
          <input class="form-control form-control-lg" type="text" placeholder="Digite seu email"
            aria-label=".form-control-lg example">

        </div>
        <div class="row align-items-center justify-content-center mt-3">
          <button class="btn btn-warning"> Cadastrar</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import img1 from "@/assets/img/service-1.webp";
import img2 from "@/assets/img/service-2.webp";
import img3 from "@/assets/img/service-3.webp";

export default {
  name: "FormComponent",
  data() {
    return {
      img: [img1, img2, img3],
    };
  },
};
</script>

<style lang="scss" scoped>
.title-service {
  color: rgb(255 144 3);
}

input {
  max-width: 400px;
}

button {
  max-width: 250px;
  height: 100%;
}
</style>
