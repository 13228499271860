<template>
  <div id="whatsapp-button" class="whatsapp-button m-2" @click="redirect">
    <i class="fab fa-whatsapp"></i>
  </div>
</template>

<script>
import { whatsapp_link } from "../configs";
export default {
  methods: {
    redirect() {
      window.open(whatsapp_link);
    },
  },
};
</script>

<style lang='scss'>
.whatsapp-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 1px 2px #888;
  z-index: 1000;
}
</style>