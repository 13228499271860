<template>
  <section id="home" class="home-container">
    <div class="container home-text">
      <div class="row mb-3">
        <h1>{{ "Bem-vindo ao Grupo Macih".toUpperCase() }}</h1>
      </div>
      <div class="row align-items-center justify-content-center">
        <p>
          Aqui você encontrará soluções extremamente eficientes e pontuais para o cilindro hidráulico de seu equipamento,
          desde vedações, cromo duro, brunimento, retíficas, fundição, usinagens de precisão e confecção de equipamentos
          novos
        </p>
      </div>

      <div class="row align-items-center justify-content-center mt-2">
        <div class="col-12 col-md-6">
          <a class="btn btn-warning whats-button" href="https://api.whatsapp.com/send?phone=551934288498"
            target="_blank">Whatsapp</a>
        </div>
        <div class="col-12 col-md-6 mt-3 mt-md-0">
          <a class="btn btn-warning" href="#about">Saiba mais</a>
        </div>
      </div>
    </div>

    <img :src="imageSrc" id="img-bg" alt="Imagem responsiva" />
    <div class="background-img"></div>
  </section>
</template>

<script>
import img1 from "@/assets/img/home-1.webp";
import img2 from "@/assets/img/home-2.webp";
import img3 from "@/assets/img/home-3.webp";
import img4 from "@/assets/img/home-4.webp";
export default {
  name: "HomeComponent",
  data() {
    return {
      page: 0,
      imgList: [img1, img2, img3, img4],
      imageSrc: img1, // URL da imagem que será exibida
    };
  },
  mounted() {
    this.page = 0;
    this.imageSrc = this.imgList[0];
    setInterval(() => {
      if (this.page == 3) this.page = 0;
      else this.page += 1;
      this.imageSrc = this.imgList[this.page];
    }, 4000);
  },
};
</script>

<style lang='scss'>
.home-container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1;

  img,
  .background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    opacity: 1;
  }

  .background-img {
    background-color: rgba(0, 0, 0, 0.733);
  }

  .home-text {
    width: 100%;
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: auto;
    color: #ffffff;

    h1 {
      text-align: center;
    }
  }

  button,
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 200px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: transparent;
    color: #fff;
    transition: background-color 0.5s ease;

    &:hover {
      background-color: rgb(255 144 3);
    }
  }
}

.whats-button {
  border: 2px solid rgb(255 144 3);

  &:hover {
    border: 2px solid #128c7e;
    background-color: #25d366 !important;
  }
}
</style>