<template>
  <section id="service-page" class="container service-page">
    <div class="row mt-5 mb-5 align-items-center justify-content-center">
      <div class="col-12 col-md-6">
        <h2 class="text-center text-md-start">Cromo duro</h2>
        <div class="mt-2 text-center text-md-start">
          <p>
            O maior benefício do banho de cromo duro em superfície novas e usadas, além de acabamento é a proteção!
            Em peças novas o cromo aumenta o tempo de vida útil do material ou equipamento, proporcionando melhor
            desempenho do mesmo.
          </p>
          <p>
            Já em superfícies usadas e desgastadas, ele reconstitui suas medidas originais,
            possibilitando a renovação da peça e aumento de vida útil do mesmo, evitando substituição de peças.
            As camadas de revestimento que utilizamos nas superfícies dos materiais é de 0,05 a 0,25mm e as medidas
            máximas que atendemos é de Ø1600 de diâmetro x 13000mm (13 metros) de comprimento.
            É uma opção segura, alta durabilidade e de ótimo custo benefício.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 p-5 p-md-0">
        <img src="@/assets/img/home-4.webp" class="" alt="..." />
      </div>
    </div>

    <div class="row mt-2 mb-5 align-items-start justify-content-center gallery">
      <h2 class="text-center text-md-center">Algumas fotos do nossos trabalhos</h2>

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

    </div>
  </section>
</template>

<script>
export default {
  name: "CromoDetails",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
    let navBar = document.getElementById("navbar");
    navBar.style.backgroundColor = "black";
  },
};
</script>

<style lang="scss">
.service-page {
  margin-top: 10vh;
  min-height: 90vh;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;

  button {
    width: 80%;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: rgb(255 144 3);
    color: #fff;
    transition: background-color 0.5s ease;
  }

  img {
    height: auto;
    object-fit: contain;
    width: 100%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
  }

  .gallery {
    img {
      max-width: 300px;
      margin: 24px;

      &:hover {
        transform: scale(1.3);
        transition: 0.5s cubic-bezier(0, 0, .3, 1);
        cursor: pointer;
      }
    }
  }
}
</style>
