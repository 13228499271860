<template>
  <div id="layout">
    <Navbar />
    <router-view>
     
    </router-view>

    <div class="content black">
    <Contact />
  </div>
    <div class="content black">
      <Footer />
    </div>
  </div>
  <Request />
  <WhatsappButton />
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Contact from "@/components/Contact.vue";
import Footer from "@/components/Footer.vue";

import Request from "@/components/Request.vue";
import WhatsappButton from "@/components/plugins/WhatsappButton.vue";

export default {
  name: "App",
  components: {
    Navbar,
    Contact,
    Footer,
    Request,
    WhatsappButton,
  },
  mounted() {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap");

h1,
h2,
h3,
h4,
p,
a,
span,
.btn,
button {
  font-family: "Libre Baskerville", serif !important;
}

p {
  font-size: 1rem !important;
}

#layout {
  max-width: 100vw;
  box-sizing: border-box !important;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: rgb(236, 229, 229);
}

.content {
  width: 100%;
  max-width: 100%;
  height: auto;
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.43),
    rgba(255, 255, 255, 0.43) 1px,
    transparent 1px,
    transparent 6px
  );
  background-size: 8px 8px;
}

.black {
  background-color: #0f0f0f;
  background-image: none;
}

.btn-warning {
  background-color: rgb(255 144 3);
}

.details{
  margin-top: 10vh;
   width: 100%;
  max-width: 100%;
  height: auto;
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-image: repeating-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.43),
    rgba(255, 255, 255, 0.43) 1px,
    transparent 1px,
    transparent 6px
  );
  background-size: 8px 8px;
}

@media (max-width: 575.98px) {
}
</style>
