<template>
  <section id="portifolio" class="container portifolio">
    <h2 class="mt-5">Conheça nossos trabalhos</h2>
    <p class="mt-2 mb-5 text-center">
      Descubra a qualidade dos nossos serviços de manutenção de cilindros
      hidráulicos através do nosso portfólio.
    </p>
    <div class="row mt-2 mb-4 align-items-start justify-content-center gallery">
      <img v-for="img in imgList" :key="img" :src="img" class="" alt=" ..." @click="openImage(img)" />
    </div>

  </section>
</template>

<script>
import img1 from "@/assets/img/Gallery/1.jpg";
import img2 from "@/assets/img/Gallery/2.jpg";
import img3 from "@/assets/img/Gallery/3.jpg";
import img4 from "@/assets/img/Gallery/4.jpg";
import img5 from "@/assets/img/Gallery/5.jpg";
import img6 from "@/assets/img/Gallery/6.jpg";
import img7 from "@/assets/img/Gallery/7.jpg";
import img8 from "@/assets/img/Gallery/8.jpg";
import img9 from "@/assets/img/Gallery/9.jpg";
import img10 from "@/assets/img/Gallery/10.jpg";
import img11 from "@/assets/img/Gallery/11.jpg";
import img12 from "@/assets/img/Gallery/12.jpg";
import img13 from "@/assets/img/Gallery/13.jpg";
import img14 from "@/assets/img/Gallery/14.jpg";
import img15 from "@/assets/img/Gallery/15.jpg";
import img16 from "@/assets/img/Gallery/16.jpg";
// import img17 from "@/assets/img/Gallery/17.jpg";

let imgList = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
];
export default {
  name: "ServicesComponent",
  data() {
    return {
      imgList: imgList, // URL da imagem que será exibida
    };
  },
  methods: {
    openImage(imgRef) {
      window.open(imgRef, '_blank', 'width=auto, height=auto');
    }
  }
};
</script>

<style lang='scss'>
.portifolio {
  min-height: 60vh;
  color: #fff;
  box-sizing: border-box !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: black;

  p {
    font-size: 14px;
  }

  img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

.carousel-inner {
  max-height: 70vh !important;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: black;
}

.carousel-indicators {
  button {
    background-color: black;
  }
}


.gallery {
  img {
    min-width: 250px;
    max-width: 250px;
    max-height: 250px;
    min-height: 250px;
    margin: 16px;
    object-fit: cover;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;

    &:hover {
      transform: scale(1.3);
      transition: 0.5s cubic-bezier(0, 0, .3, 1);
      cursor: pointer;
    }
  }
}
</style>