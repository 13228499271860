<template>
  <section id="service-page" class="container service-page">
    <div class="row mt-5 mb-5 align-items-center justify-content-center">
      <div class="col-12 col-md-6">
        <h2 class="text-center text-md-start">Fabricação</h2>
        <div class="mt-2 text-center text-md-start">
          <p>
            Se tratando de recuperação de cilindros, nossa equipe de especialistas trata toda todas as situações e
            processo com extrema rigidez, desde a desmontagem/peritagem do equipamento, até os processo finais de testes
            em bancada hidráulica, garantindo assim a eficácia do serviço executado, lucratividade para seu equipamento e
            satisfação total de nossos clientes!
            Hoje, nossa estrutura consegue atender de maneira sólida e resoluta cilindros hidráulicos de até Ø1600 x
            13.000mm de comprimento, sendo eles de diversos tipos, tamanhos, aplicações e áreas de atuação.
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 p-5 p-md-0">
        <img src="@/assets/img/home-4.webp" class="" alt="..." />
      </div>
    </div>

  </section>
</template>

<script>
export default {
  name: "FrabricacaoDetail",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
    let navBar = document.getElementById("navbar");
    navBar.style.backgroundColor = "black";
  },
};
</script>

<style lang="scss" scoped>
.service-page {
  margin-top: 10vh;
  min-height: 90vh;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;

  button {
    width: 80%;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: rgb(255 144 3);
    color: #fff;
    transition: background-color 0.5s ease;
  }

  img {
    height: auto;
    object-fit: contain;
    width: 100%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
  }
}
</style>
