<template>
  <section id="blog" class="container blog">
    <div class="title-service mt-5">
      <h2>Blog</h2>
      <p>
        <small>Aprenda mais sobre manutenção e recuperação de cilindros
          hidráulicos.</small>
      </p>
    </div>
    <div class="row mb-5">
      <div class="col-12 p-3 d-flex justify-content-center col-md-4" v-for="(i, index) in img" :key="`blog-${index}`">
        <div class="card">
          <div class="incoming">Em breve...</div>
          <img :src="i" class="card-img-top" alt="..." />
          <div class="card-body">
            <h5 class="card-title">Título</h5>
            <p class="card-text">Descrição</p>
            <p class="card-text">
              <small class="text-muted">Data</small>
            </p>

            <div class="row p-2 justify-content-center">
              <button class="btn btn-warning">Ler mais</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import img1 from "@/assets/img/service-1.webp";
import img2 from "@/assets/img/service-2.webp";
import img3 from "@/assets/img/service-3.webp";

export default {
  name: "ServicesComponent",
  data() {
    return {
      img: [img1, img2, img3],
    };
  },
};
</script>

<style lang='scss' scoped>
.blog {
  width: 100%;
  min-height: 80vh;
  box-sizing: border-box !important;
  border-top: 1px solid black;
  position: relative;

  .incoming {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.918);
    border-radius: 10px;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    color: rgb(255 144 3);
  }

  .card {
    cursor: pointer;
    min-width: 90%;
    max-width: 90%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3) !important;

    button {
      border-radius: 50px;
      max-width: 80%;
    }

    &:hover {
      transform: scale(1.05);
    }
  }

  .card-img-top {
    max-height: 250px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover !important;
  }

  .title-service {
    margin-top: 0px;
    padding-top: 3%;
    color: black;
  }

  col {
    border: 1px solid black;
  }
}
</style>