<template>
  <footer id="footer" class="container">
    <div class="row footer align-items-center">

      <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-start footer-col">
        <div class="row justify-content-center justify-content-md-start  mt-3">
          <img src="@/assets/img/logo-macih-invert.png" alt="Logo do grupo Macih" />

          <p class="mt-3 text-center text-md-start">
            Rua Tupis, 235 <br> Jd.Pacaembu, Piracicaba - SP
          </p>
        </div>
      </div>

      <div class="col-12 col-md-6 d-flex justify-content-center justify-content-md-end">
        <div class="row">

          <div class="col-12 text-center text-md-end">
            <button variant="light" class="me-2">
              <i class="fab fa-facebook" @click="goTo('https://www.facebook.com/grupomacih')"></i>
            </button>
            <button variant="light" class="me-2">
              <i class="fab fa-instagram" @click="goTo('https://www.instagram.com/grupomacih/')"></i>
            </button>
          </div>

          <div class="col-12 text-center text-md-end">
            <div class="row">
              <p>
                <i class="fa fa-phone m-2" aria-hidden="true"></i>
                Phone: <a href="tel:+5519997074938">(19) 3428-8498</a>
              </p>
            </div>

            <div class="row">
              <p>
                <i class="fa fa-envelope m-2" aria-hidden="true"></i>E-mail:
                <a href="mailto:comercial@www.manutencaodecilindros.com.br">comercial@grupomacih.com.br</a>
              </p>
            </div>


          </div>
        </div>
      </div>

      <div class="row align-items-center justify-content-center text-center">
        <p>© 2022 Grupo Macih. Todos os Direitos Reservados</p>
      </div>
    </div>
  </footer>
</template>

<script>
import img from "@/assets/img/about.jpg";

export default {
  name: "ServicesComponent",
  data() {
    return {
      imageSrc: img, // URL da imagem que será exibida
    };
  },
  methods: {
    goTo(url) {
      var link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style lang='scss'>
.footer {
  min-height: 40vh;
  padding-bottom: 3%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  color: #fff;
  border-top: 1px solid #fff;

  .fab {
    font-size: 1.5;

    &:hover {
      color: rgb(255 144 3);
    }
  }

  button {
    font-size: 2rem;
    color: #fff;
    border: none;
    background-color: transparent;
  }

  img {
    max-height: 100px;
    width: auto;
    object-fit: contain;

  }

}
</style>