<template>
  <section id="service-page" class="container service-page">
    <div class="row mt-5 mb-4 align-items-center justify-content-center">
      <div class="col-12 col-md-6">
        <h2 class="text-center text-md-start">Mandrilhamento, Brunimento e trepanação</h2>
        <div class="mt-2 text-center text-md-start">
          <p>
            <bold>Mandrilhamento:</bold> processo ferramental utilizado para aumentar furos ou melhorar a qualidade de um
            furo
            existente em peças, através do sistema de usinagem. <br>
            <bold>Brunimento:</bold>processo de usinagem por abrasão, com objetivo de eliminar riscos, poros ou
            imperfeições de
            diâmetro e geometrias internas, dando assim acabamento e deixando o material nas medidas e tolerâncias
            exigidas conforme regulamentos ou aplicações. <br>
            <bold>Trepanação:</bold>técnica de usinagem executada em material quadrado ou redondo, onde são executados
            furos grandes
            e o material do centro é retirado em forma de miolo, tendo aproveitamento total das sobras.
            Atendemos as medidas mínimas de Ø25mm e máximas de Ø500mm x 12.000mm <br>
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6 p-5 p-md-0">
        <img src="@/assets/img/home-4.webp" class="" alt="..." />
      </div>
    </div>



    <div class="row mt-4 mb-5 align-items-start justify-content-center gallery">
      <h2 class="text-center text-md-start">Veja um pouco mais do nosso trabalho...</h2>

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

      <img src="@/assets/img/home-4.webp" class="" alt="..." />

    </div>
  </section>
</template>

<script>
export default {
  name: "FrabricacaoDetail",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
    let navBar = document.getElementById("navbar");
    navBar.style.backgroundColor = "black";
  },
};
</script>

<style lang="scss">
.service-page {
  margin-top: 10vh;
  min-height: 90vh;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;

  button {
    width: 80%;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: rgb(255 144 3);
    color: #fff;
    transition: background-color 0.5s ease;
  }

  img {
    height: auto;
    object-fit: contain;
    width: 100%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
  }

  .gallery {
    img {
      max-width: 300px;
      margin: 24px;

      &:hover {
        transform: scale(1.3);
        transition: 0.5s cubic-bezier(0, 0, .3, 1);
        cursor: pointer;
      }
    }
  }
}
</style>
