<template>
  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Contato</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="page = 1"></button>
        </div>
        <div class="modal-body">
          <div class="container" v-if="page == 1">
            <div class="row">
              <p class="text-center">Selecione uma opção para solicitar um orçamento</p>
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <button class="btn-orc btn btn-warning" @click="redirect">
                  Via Whatsapp
                </button>

              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex justify-content-center">
                <!-- <button class="btn-orc btn btn-warning mt-3" @click="page = 2">
                                  Enviar mensagem
                                </button> -->
              </div>
            </div>
          </div>
          <div class="container" v-if="page == 2">
            <h2>Solicitar Orçamento</h2>
            <form>
              <div class="mb-3">
                <label for="name" class="form-label">Nome</label>
                <input type="text" class="form-control" id="name" v-model="form.name" required>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">E-mail</label>
                <input type="email" class="form-control" id="email" v-model="form.email" required>
              </div>
              <div class="mb-3">
                <label for="message" class="form-label">Mensagem</label>
                <textarea class="form-control" id="message" v-model="form.message" rows="4" required></textarea>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="page = 1">
            Fechar
          </button>
          <button type="button" class="btn btn-warning" v-if="page == 2" @click="submitForm">Enviar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { whatsapp_link_msg } from "@/components/configs";

export default {
  name: "RequestModal",
  data() {
    return {
      page: 1,
      form: {
        name: '',
        email: '',
        message: ''
      }
    }
  },
  mounted() {
    var myModalEl = document.getElementById('exampleModal')
    myModalEl.addEventListener('hidden.bs.modal', function () {

      this.page = 1;
    })
  },
  methods: {
    redirect() {
      window.open(whatsapp_link_msg);
    },
    submitForm() {
      // Aqui você pode implementar a lógica para enviar o formulário
      // por meio de uma chamada HTTP ou qualquer outra forma desejada.
      // Por exemplo, você pode usar Axios para fazer uma requisição POST.
      console.log(this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-orc {
  min-width: 200px;
  min-height: 100px;
}
</style>