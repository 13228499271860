<template>
  <section id="service-page" class="container service-page">
    <div class="row mt-5 mb-5 align-items-start justify-content-center">
      <div class="col-12 col-md-6 p-5 p-md-0">
        <img src="@/assets/img/home-4.webp" class="" alt="..." />
      </div>
      <div class="col-12 col-md-6">
        <h2 class="text-center text-md-center mt-5">Engenharia e projetos especiais</h2>
        <div class="mt-2 text-center text-md-center">
          <p>
            Se atualizando diariamente com todas as novidades expostas no mundo, e em constante contato com nossos
            clientes, nosso grupo se atualiza das mais diversas tecnologias da atualidade relacionados à hidráulica e
            correlatos, buscando cada vez mais conhecimento e experiência, afim de sanar necessidades de nossos parceiros,
            automatizar processos e equipamentos, aplicando a melhoria continua incessantemente para um sucesso mutuo
            final!
          </p>

          <p>FAÇA UMA COTAÇÃO HOJE MESMO!</p>
        </div>
      </div>

    </div>


    <div class="row text-center mb-5">
      <h2 class="text-center">Dados técnicos</h2>
      <table class="table mt-2">
        <thead>
          <tr>
            <th scope="col">Produto</th>
            <th scope="col">Largura</th>
            <th scope="col">Altura</th>
            <th scope="col">Comprimento</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
          <tr>
            <th scope="row">Produto 1</th>
            <td>1000mm</td>
            <td>2000mm</td>
            <td>3000mm</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
export default {
  name: "EngenhariaDetails",
  components: {},
  mounted() {
    window.scrollTo(0, 0);
    let navBar = document.getElementById("navbar");
    navBar.style.backgroundColor = "black";
  },
};
</script>

<style lang="scss">
.service-page {
  margin-top: 10vh;
  min-height: 90vh;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;

  button {
    width: 80%;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: rgb(255 144 3);
    color: #fff;
    transition: background-color 0.5s ease;
  }

  img {
    height: auto;
    object-fit: contain;
    width: 100%;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
    text-align: center;
    background-color: #8f8d8d;

    ::after {
      content: 'Image content';
    }
  }
}
</style>
