<template>
  <nav id="navbar" class="navbar navbar-expand-lg navbar-light bg-light p-0">
    <div class="container-fluid">
      <a class="navbar-brand" href="/#">
        <img src="@/assets/img/logo-macih-invert.png" alt="Logo Grupo Macih" />
      </a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div
          class="navbar-nav d-flex justify-content-center align-items-center"
        >
          <a class="nav-link active" aria-current="page" href="/#home">Home</a>
          <a class="nav-link" href="/#about">Sobre nós</a>
          <a class="nav-link" href="/#services">Serviços</a>

          <a class="nav-link" href="/#portifolio">Portifólio</a>
          <a class="nav-link" href="/#blog">Blog</a>
          <a class="nav-link" href="/#contact">Contato</a>
          <button
            class="btn btn-warning mt-2 mb-3"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Solicitar Orçamento
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  import { logAnalyticsEvent } from "@/database/index";

  export default {
    name: "NavbarComponent",
    data() {
      return {
        minimize: false,
      };
    },
    mounted() {
      const navbar = document.getElementsByClassName("navbar")[0];
      const navbarHeight = navbar.offsetHeight;
      var router = this.$router;
      const toggle = document.getElementsByClassName("navbar-toggler-icon")[0];
      toggle.addEventListener("click", this.setBackground);

      window.addEventListener("scroll", function () {
        console.log(router)
        if (window.scrollY > 0) {
           navbar.classList.add("scrolled");
        } else {
           navbar.classList.remove("scrolled");
           if (router.currentRoute._rawValue.name !== "Home")navbar.classList.add("scrolled");
        }
      });

      var links = document.querySelectorAll('a[href*="#"]');
      var self = this;
     
      // adiciona um evento de clique para cada link selecionado
      links.forEach(function (link) {
        link.addEventListener("click", async function (event) {
          await router.push("/").then(() => {
            // verifica se o hash está presente no URL atual
            self.setBackground();
            if (this.hash !== "") {
              // previne o comportamento padrão do link
              event.preventDefault();

              // armazena o hash
              var hash = this.hash;

              // seleciona a div de destino
              var target = document.querySelector(hash);

              // calcula a posição da div de destino em relação ao topo da página, subtraindo a altura da navbar
              var targetPosition = target.offsetTop - navbarHeight;

              // faz o scroll suave para a posição da div de destino
              window.scrollTo({
                top: targetPosition,
                behavior: "smooth",
              });

              // adiciona o hash ao URL quando o scroll suave estiver completo
              history.pushState(null, null, hash);
              const toggle = document.getElementById("navbarNavAltMarkup");
              toggle.classList.remove("show");
            }
          });
        });
      });
    },
    methods: {
      setBackground() {
        const navbar = document.getElementsByClassName("navbar")[0];
        if (!navbar.classList.contains("scrolled")) {
          navbar.classList.add("scrolled");
        }
      },
      openRequest() {
        console.log("Open_resquest");
        logAnalyticsEvent("button_click", { button_name: "Sign Up" });
      },
    },
  };
</script>

<style lang="scss">
  .navbar {
    z-index: 999;
    left: 0;
    top: 0;
    padding: 0px 10px;
    position: fixed;
    width: 100vw;
    max-width: 100vw;
    min-height: 10vh !important;
    max-height: auto !important;
    background-color: transparent !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s ease;

    .navbar-collapse {
      justify-content: flex-end;
      align-items: flex-end;
    }

    .navbar-toggler {
      border: 1px solid rgb(255 144 3) !important;
      background-color: rgb(255 144 3);
    }

    a {
      color: #fff !important;
      margin: 0px 10px;
      text-decoration: none;
      //

      &:hover {
        color: rgb(255 144 3) !important;
        transition: border 0.5s ease;
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-height: 35px;
      max-width: 70%;
      margin: 0px 10px;
      color: #fff;
    }

    img {
      object-fit: contain;
      max-height: 10vh;
    }
  }

  .scrolled {
    background-color: #0f0f0f !important;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3) !important;
  }
</style>
