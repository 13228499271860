<template>
  <section id="contact" class="container contact">
    <div class="row mb-5 mt-5">
      <div class="col-12 col-md-6 d-flex text-center text-md-start justify-content-center align-items-center">
        <div class="row">
          <div class="d-flex justify-content-md-start justify-content-center mb-5">
            <h2>Contato</h2>
          </div>

          <p>
            <i class="fa fa-home m-2" aria-hidden="true"></i>Rua Tupis, 235 - Jd.Pacaembu, Piracicaba - SP
          </p>
          <p>
            <i class="fa fa-phone m-2" aria-hidden="true"></i>Phone: (19)
            3428-8498
          </p>
          <p>
            <i class="fa fa-envelope m-2" aria-hidden="true"></i>E-mail:
            comercial@grupomacih.com.br
          </p>

          <div class="row text-center text-md-start mt-5">
            <div class="col-12 fs-1">
              <i class="fab fa-facebook m-2" @click="goTo('https://www.facebook.com/grupomacih')"></i>

              <i class="fab fa-instagram m-2" @click="goTo('https://www.instagram.com/grupomacih/')"></i>
            </div>
          </div>

          <div class="row m-0 text-center text-md-start mt-5">
            <div class="col-12 mt-4 col-md-6 d-flex justify-content-center justify-content-md-start">
              <a class="btn btn-warning d-flex justify-content-center align-items-center whats-button" @click="redirect">
                <i class="fa fa-whatsapp fs-3 m-2 whats-button" aria-hidden="true"></i>Whatsapp
              </a>
            </div>
            <div class="col-12 mt-4 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start">
              <a class="btn btn-warning" data-bs-toggle="modal" data-bs-target="#exampleModal">Solicitar orçamento</a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 d-flex justify-content-center align-items-center mt-5">
        <div class="ratio ratio-1x1">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d919.8252907077751!2d-47.641849483797046!3d-22.754202158662757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c6305e05d89e3b%3A0x41445baad70d6bd6!2sR.%20Tupis%2C%20235%20-%20Paulic%C3%A9ia%2C%20Piracicaba%20-%20SP%2C%2013424-302!5e0!3m2!1spt-BR!2sbr!4v1698007564140!5m2!1spt-BR!2sbr"
            width="auto" height="450" style="border:0;" allowfullscreen="" loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { whatsapp_link } from "@/components/configs";

export default {
  name: "ServicesComponent",
  data() {
    return {};
  },
  methods: {
    redirect() {
      window.open(whatsapp_link);
    },
    goTo(url) {
      var link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
    }
  }
};
</script>

<style lang='scss'>
.contact {
  min-height: 90vh;
  box-sizing: border-box !important;

  color: #fff;

  h2 {
    color: rgb(255 144 3);
  }

  iframe {
    box-shadow: 2px 2px 4px rgb(255 144 3) !important;
  }

  .fab {
    cursor: pointer;
    font-size: 3.5rem;

    &:hover {
      color: rgb(255 144 3);
    }
  }

  p {
    font-size: 0.9rem !important;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 200px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid rgb(255 144 3);
    background-color: rgb(255 144 3);
    color: #fff;
    transition: background-color 0.5s ease;
  }

  .whats-button {
    i {
      border: none;
    }
  }
}
</style>